.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  opacity: 1;
  translate: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #181c1f;
  padding: 2rem;

  color: var(--colorWhite);
  animation: slideDown 0.3s;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    animation-timing-function: ease;
  }
  25% {
    transform: translateY(-75%);
    animation-timing-function: ease;
  }
  50% {
    transform: translateY(-50%);
    animation-timing-function: ease;
  }
  75% {
    transform: translateY(-25%);
    animation-timing-function: ease;
  }
  100% {
    transform: translateY(0%);
    animation-timing-function: ease;
  }
}

.closing {
  animation: slideUp 0.3s;
  animation-fill-mode: backwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(0%);
    animation-timing-function: ease;
  }
  25% {
    transform: translateY(-25%);
    animation-timing-function: ease;
  }
  50% {
    transform: translateY(-50%);
    animation-timing-function: ease;
  }
  75% {
    transform: translateY(-75%);
    animation-timing-function: ease;
  }
  100% {
    transform: translateY(-100%);
    animation-timing-function: ease;
  }
}

.modal_login_container {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.modal_loader {
  align-self: center;
}

.modal_btn {
  flex-basis: 35%;
  width: 100%;
  padding: 0.6rem 1rem;
  cursor: pointer;
  border: 1px solid white;
  text-align: center;
  transition: all 150ms ease;
  transition-delay: 100ms;
}

.modal_btn:hover {
  background: linear-gradient(to right, transparent 50%, var(--colorWhite20) 50%) right;
  background-size: 200% 100%;
}

.modal_body {
  position: relative;
  padding: 35px 50px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background: rgba(41,47,54,0.75);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  max-width: 1300px;
  width: 75%;
  height: 100%;
  max-height: 1200px;
  overflow-y: scroll;
  gap: 20px;
}

.modal_login_body {
  height: min-content;
  width: 60%;
}

.login_back_link {
  cursor: pointer;
  font-size: 12px;
}

.login_back_link:hover {
  text-decoration: underline;
}

.modal_body::-webkit-scrollbar {
  display: none;
}

.modal_header {
  text-align: center;
  font-size: 32px;
}

.modal_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  flex: 3 0 50%;
}

.modal_content_info {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  height: min-content;
}

.content_info__item {
  flex-basis: 45%;
}

.content_info__item p, .modal_content_info p {
  font-size: 18px;
  padding: 0 0 4px 8px;
}

.modal_content_info.grown_item {
  flex-direction: column;
  flex-grow: 2;
}

.modal_input {
  appearance: none;
  width: 100%;
  padding: 4px 16px;
  color: var(--colorWhite);
  border-radius: 3px;
  background-color: transparent;
  border-bottom: 1px solid var(--colorBlue);
}

.input_invalid {
  border-bottom: 1px solid var(--colorRed);
}

.modal_textarea {
  padding: 12px 20px;
  resize: none;
  width: 100%;
  color: var(--colorWhite);
  border: 1px solid white;
  outline: none;
  user-select: all !important;
  -webkit-user-select: text !important;
}

.modal_textarea__readonly {
  flex-grow: 2;
  color: #8e8e91;
}

.readonly {
  background-color: var(--bodyDark);
  border: none;
  color: #8e8e91;
}

.modal_textarea__editable {
  max-height: 75px;
}

.modal_textarea::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.modal_textarea::-webkit-scrollbar-track {
  background: #c5c6c7;
}

/* Handle */
.modal_textarea::-webkit-scrollbar-thumb {
  background: #6391b0;
  transition: background-color 300ms ease;
  border-radius: 6px;
}

/* Handle on hover */
.modal_textarea::-webkit-scrollbar-thumb:hover {
  background: #6391b3;
}

input.modal_input:focus, input.modal_input:focus-within, input.modal_input_invalid:focus, input.modal_input_invalid:focus-within {
  outline: none;
}

input.modal_input:focus-within {
  outline: none;
}

.modal_content_info::-webkit-scrollbar {
  display: none;
}

.modal_btn_container {
  margin: 2rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_error {
  color: var(--colorRed);
}

.modal_sent_success {
  display: grid;
  place-items: center;
  padding: 1.5rem 3rem;
  border-radius: 3px;
  background-color: rgba(41,47,54,0.75);
}

.modal_sent_success span {
  font-size: 24px;
}

@media (max-width: 1024px) {
  .modal_body {
    width: 100%;
  }

  .modal_textarea__readonly {
    min-height: 30vh;
  }

  .modal_header {
    font-size: 24px;
  }
}

@media (max-width: 671px) {
  .modal {
    padding: 0;
    margin: 0;
  }

  .content_info__item {
    flex-basis: 100%;
  }

  .modal_textarea__editable {
    max-height: 100px;
  }
}

@media (max-width: 576px) {

  .modal_body {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
  }

}
