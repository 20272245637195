@font-face {
  font-family: "AeonikPro-Regular";
  src: url("../assets/fonts/AeonikPro-Regular.otf");
}

@tailwind base;
.bg-gradient-warm {
  background-image: radial-gradient(#560e0f 1px, transparent 1px);
  background-size: 3px 3px;
  background-color: #181c1f;
}

.bg-gradient-cold {
  background-image: radial-gradient(#0e3273 1px, transparent 1px);
  background-size: 3px 3px;
  background-color: #181c1f;
}

.bg-gradient-dark {
  background-color: var(--colorDark);
}

.border-hot {
  border: 2px solid var(--colorRed);
}

.border-cold {
  border: 2px solid var(--colorBlue);
}

@tailwind components;

.parent-gradient {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  transition: all .5s ease-out;
}

.child-gradient {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.child-gradient::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(252, 138, 89, 1);
  background-image: var(--gradient);
  background-size: 600%;
  background-repeat: no-repeat;
  background-position: left;
  transition: background-position;
  transition-duration: .4s;
}

@tailwind utilities;


html,
body {
  font-family: AeonikPro-Regular, serif;
  font-size: 100%;
  color: var(--colorWhite);
}

body::-webkit-scrollbar {
  display: none;
}

*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: AeonikPro-Regular, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

:root {
  /* Pallete */
  --colorDark: #292F36;
  --colorBlue: #4ECDC4;
  --colorWhite: #FFFFFF;
  --colorRed: #FF6B6B;


  --bodyDark: #0c1113;
  --colorMain: #638ec8;
  --colorMainDark: #32343A;
  --colorBright: #CBCBCD;
  --colorVeryBright: #B0B1B5;
  --colorSuperBright: #E5E5E6;
  --colorError: rgb(208, 147, 147);
  --colorBackgroundFooter: #374458;
  --colorBackgroundFooterMobile: #3b619f;
  --colorBackgroundHot: rgb(244, 237, 237);
  --colorBackgroundCold: rgb(237, 237, 244);
  --colorHot: rgb(255, 200, 200);
  --colordCold: rgb(200, 200, 255);
  --green: #3FFD52;
  --gradient: linear-gradient(198deg,
  rgba(252, 138, 89, 1) 0%,
  rgba(233, 90, 179, 1) 20%,
  rgba(208, 89, 235, 1) 40%,
  rgba(154, 137, 241, 1) 61%,
  rgba(123, 170, 243, 1) 80%,
  rgba(176, 253, 255, 1) 100%);
  --colorWhite20: rgba(255, 255, 255, .2);
  --colorWhite80: rgba(255, 255, 255, .8);
  --colorWhite100: rgba(255, 255, 255, 1);
  --colorModalOverlay: rgba(11, 12, 16, 0.91);
  --colorModalOpacity: #1f2833;
  --colorInputReadonly: #141d26;
}

/* remove arrows from input (Chrome, Safari, Edge, Opera) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* remove arrows from input (Firefox) */
input[type=number] {
  -moz-appearance: textfield;
}


.MuiSlider-root .MuiSlider-thumb {
  z-index: 2;
  cursor: pointer;
  pointer-events: all;
}

.MuiSlider-root .MuiSlider-thumb:hover {
  cursor: pointer;
}

.css-14gf62f-MuiSlider-thumb,
.css-14gf62f-MuiSlider-thumb:hover {
  pointer-events: auto;
}

.unit {
  border: none;
  width: 4rem !important;
  margin: 0 0 0 0.5rem !important;
  background-color: transparent !important;
}

.unit__menu-list {
  background-color: transparent !important;
}

.unit__indicator-separator {
  display: none;
}

.unit__indicator {
  color: var(--colorWhite);
  padding: 0 !important;
}

.unit__indicator svg {
  width: 15px;
}

.unit__value-container, .unit__value-container--has-value {
  margin: 0;
  padding: 0 !important;
}

.unit__control, .unit__control--is-focused, .unit__control--menu-is-open {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.unit__single-value {
  font-size: .9rem;
  color: white !important;
  margin: 0 !important;
}

.unit__menu {
  margin: 0;
  background: rgba( 255, 255, 255, 0.3 ) !important;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4.5px );
  -webkit-backdrop-filter: blur( 4.5px );
  border-radius: 10px;
}

.unit__option {
  background-color: transparent !important;
  padding: 5px 0 5px 5px !important;
}

.unit__option--is-selected {
  background-color: var(--colorWhite20) !important;
}

.unit__option--is-focused {
  background: rgba( 74, 74, 74, 0.3 ) !important;
}


/*     Media queries    */

@media (max-width: 576px) {

  html,
  body {
    font-size: 87%;
  }

  .unit {
    width: 3rem !important;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {

}

@media (max-width: 992px) {

}

@media (max-width: 1200px) {

}

/* ******* Animations ********* */

/*@-webkit-keyframes button_hover {*/
/*  0% {*/
/*    -webkit-transform: scale(0.8, 0.8);*/
/*    */
/*  }*/
/*  50% {*/
/*    -webkit-transform: scale(1, 1);*/
/*    opacity: 1.0;*/
/*  }*/
/*  100% {*/
/*    -webkit-transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*}*/

/*@-moz-keyframes button_hover {*/
/*  0% {*/
/*    transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*  50% {*/
/*    transform: scale(1, 1);*/
/*    opacity: 1.0;*/
/*  }*/
/*  100% {*/
/*    transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*}*/

/*@-ms-keyframes button_hover {*/
/*  0% {*/
/*    -ms-transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*  50% {*/

/*    -ms-transform: scale(1, 1);*/
/*    opacity: 1.0;*/
/*  }*/
/*  100% {*/
/*    -ms-transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*}*/

/*@-o-keyframes button_hover {*/
/*  0% {*/
/*    transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*50% {*/
/*transform: scale(1, 1);*/
/*opacity: 1.0;*/
/*}*/

/*100*/
/*%*/
/*{*/
/*  transform: scale(0.8, 0.8);*/
/*  opacity: 0.3;*/
/*}*/
/*}*/

/*@keyframes button_hover {*/
/*  0% {*/
/*    transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*  50% {*/
/*    transform: scale(1, 1);*/
/*    opacity: 1.0;*/
/*  }*/
/*  100% {*/
/*    transform: scale(0.8, 0.8);*/
/*    opacity: 0.3;*/
/*  }*/
/*}*/

