.footer_middle {
    width: 100%;
    padding: 0.5px;
    background: var(--colorWhite);
    margin: 3rem auto
}

.footer_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_bottom_left {
    display: flex;
    flex-direction: column;
}

.footer_bottom_left_a {
    color: var(--colorWhite);
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: var(--colorWhite80)
}
.footer_bottom_left_a:hover {
    transition: all;
    transition-duration: 200ms;
    text-decoration-color: white;
    color: white;
}

.footer_bottom_left_space {
    padding: 0 0.5rem;
}

@media (max-width: 750px) {

    .footer_top {
        flex-direction: column;
    }

    .footer_top_text_container {
        align-items: center;
    }

    .footer_bottom {
        flex-direction: column;
        padding-bottom: 2rem;
    }
}

@media (max-width: 1000px) {

    .footer_bottom {
        padding-bottom: 2rem;
    }
}
