.results {
    width: 100%;
    height: 100%;
}

.results_title {
    font-size: 1.5rem;
    color: white;
    text-align: center;
}

.results_section_nav {
    position: relative;
    width: 100%;
    display: flex;
}

.results_section_nav::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: var(--colorBright);
}

.results_section_nav_item {
    flex: 1;
    cursor: pointer;
    width: 33%;
}

.results_section_nav_text {
    color: var(--colorWhite);
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 0.3rem;
}

.results_section_nav_text_active {
    color: white;
}

.results_section_nav_line_active {
    margin-top: 1rem;
    height: .3rem;
    translate: 0 50%;
    background-color: var(--colorBright);
    animation-name: lineGrow;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.39, 0.89, 0.65, 0.43);
}

@keyframes lineGrow {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.results_tab_container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 45px);
}

.results_tab_row {
    display: flex;
    align-items: center;
}

.results_tab_row:first-child {
    margin-top: 2rem;
}

.results_tab_desc {
    flex: 1;
    text-align: end;
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
}

.results_tab_value {
    flex: 1;
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
}

.results_tab_specification_container {
    flex-grow: 2;
    padding: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
}

.results_tab_specification_title {
    font-size: 1.5rem;
    text-align: center;
}

.results_tab_specification_title2 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    padding: 0.5rem 0 0 0;
}

.results_tab_specification_oversize {
    display: flex;
    align-items: center;
    justify-content: center;
}

.results_tab_specification_login {
    padding: .75rem 2.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    border: 1px solid var(--colorWhite);
    transition: all 150ms ease-in-out;
    transition-delay: 100ms;
}

.results_tab_specification_login:hover {
    background: linear-gradient(to right, transparent 50%, var(--colorWhite20) 50%) right;
    background-size: 200% 100%;
}

.results_tab_specification_login:active {
    box-shadow: 0 0 1rem var(--colorWhite20);
}

.results_tab_specification_login svg {
    width: 2rem;
    height: 2.5rem;
}

.results_tab_specification_file {
    margin: 2rem 0;
    display: grid;
    place-items: center;
}

.results_tab_specification_oversize_text {
    font-size: 3rem;
    color: var(--green);
}

.results_tab_specification_container_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 0 0;
    margin-bottom: 2rem;
}

.results_tab_specification_container_bottom_text {
    font-size: 1rem;
    text-align: center;
    line-height: 1.5rem;
}

.results_tab_specification_download {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.results_tab_specification_download:not(:last-child) {
    margin-bottom: 5px;
}

.results_tab_specification_download svg {
    width: 1.5rem;
    height: 1.5rem;
}

.results_tab2_desc {
    flex: 1.2;
    text-align: end;
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
}

.results_tab2_value {
    flex: 1;
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
}

.results_tab2_value_hot {
    flex: 1;
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
    color: var(--colorRed);
}

.results_tab2_value_cold {
    flex: 1;
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
    color: var(--colorBlue);
}


/* TAB 3 */

.results_tab3_container_elineSmall {
    display: flex;
}

.results_tab3_container_elineBig {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.results_tab3_left_elineSmall {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.results_tab3_inlet_setings {
    padding: 1rem 0;
}

.results_tab3_inlet_setings_title {
    font-size: 1.2rem;
    padding: 0.2rem 0;
    text-align: center;
}

.results_tab3_inlet_setings_row {
    padding: 0.2rem 0;
    display: flex;
    align-items: center;
}

.results_tab3_inlet_setings_row_input {
    color: red;
    background: yellow;
}

.results_tab3_inlet_setings_row_label {
    padding: 0 0 0 0.2rem;
}


.results_tab3_right_elineSmall {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.results_tab3_inlet_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.results_tab3_btn {
    width: 100%;
    padding: 0.6rem 2rem;
    margin-top: auto;
    cursor: pointer;
    border: 1px solid white;
    text-align: center;
    transition: all 150ms ease-in-out;
    transition-delay: 100ms;
}

.results_tab3_btn:hover {
    background: linear-gradient(to right, transparent 50%, var(--colorWhite20) 50%) right;
    background-size: 200% 100%;
}

.results_tab3_btn:active {
    box-shadow: 0 0 1rem var(--colorWhite20);
}

.results_no_result {
    font-size: 1.2rem;
    text-align: center;
    padding: 2rem 0 2rem 0;
}

@media (max-width: 1024px) {
    .results {
        height: 100%;
    }

    .results_section_nav {
        translate: 0 2.5rem;
    }

    .results_tab_container {
        margin-top: 3rem;
        padding-inline: 2rem;
        padding-top: 1rem;
    }

    .results_tab3_btn {
        margin-top: 1rem;
    }
}
