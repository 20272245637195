.home {
  min-height: 100vh;
  width: 100%;
}

.home_main {
  max-width: 1200px;
  margin: auto;
  padding: 0 0 2rem 0;
  display: flex;
}


.home_input_container_content {
  display: flex;
  align-items: center;
  color: white;
  justify-content: flex-end;
}

.home_input_title {
  flex: 1;
  font-size: 1rem;
  color: white;
  margin: 0 1rem 0 0;
}

.home_input {
  margin: 0;
  font-size: 1rem;
  width: 8.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  outline: none;
  border: var(--colorVeryBright) solid 0.1rem;
  background-color: transparent;
}

.home_input:focus {
  border: white solid 0.1rem;
}

.home_input:disabled {
  background-color: transparent;
}

.home_input_unit {
  font-size: .9rem;
  color: white;
  width: 4rem;
  margin: 0 0 0 0.5rem;
}

.home_input_validation {
  border: 1px solid transparent;
  position: relative;
  max-width: 12rem;
  border-radius: 3px;
  right: 0;
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding: 0 .5rem;
  margin-top: 5px;
  margin-right: 3rem;
  text-align: center;
  overflow-wrap: break-word;
  color: var(--colorRed);
}

.home_input:-webkit-autofill,
.home_input:-webkit-autofill:focus,
.home_input:-webkit-autofill:hover,
.home_input:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.home_input[data-autocompleted] {
  background-color: transparent !important;
}

.results_tab3_btn {
  width: 100%;
  padding: 0.6rem 2rem;
  cursor: pointer;
  border: 1px solid white;
  text-align: center;
  transition: all 150ms ease-in-out;
  transition-delay: 100ms;
}

.results_tab3_btn:hover {
  background: linear-gradient(to right, transparent 50%, var(--colorWhite20) 50%) right;
  background-size: 200% 100%;
}

.results_tab3_btn:active {
  box-shadow: 0 0 1rem var(--colorWhite20);
}

.home_btn {
  width: 100%;
  padding: 0.6rem 2rem;
  cursor: pointer;
}

.home_btn:active {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}


/* ----- RESULTS ----- */

.home_results {
  position: relative;
  width: 50%;
  margin-left: 3rem;
  z-index: 10;
}

.home_results_container {
  position: sticky;
  top: 2rem;
  width: 100%;
  height: 100%;
}

.home_results_invisible {
  height: 100%;
}

.home_results_visible {
  animation: openResults ease-in-out 1s;
}

@keyframes openResults {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    opacity: 100%;
  }
}

.home_results_closeSVG {
  visibility: hidden;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.home_results_nav {
  display: none;
  width: 100%;
  background: var(--colorBackgroundFooter);
  padding: 0.5rem 2rem 0.5rem 1rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: var(--colorMain) solid;
  border-width: 1px 0 0 0;
}

.home_results_nav_mobile {
  display: none;
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 1rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: white solid;
  backdrop-filter: blur(10px);
  background-color: rgba(15, 2, 26, 0.9);
  border-width: 1px 0 0 0;
}

.home_results_nav_text {
  padding: 1rem 1.5rem 1rem 1rem;
  font-size: 1.5rem;

}

.home_results_svg_arrow {
  transform-origin: center;
  transition: all 0.5s ease-in-out;
}

.home_results_svg_arrow_rotate {
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}

.unit__indicators {
  display: none !important;
}

/*       Media Queries     */

@media (max-width: 576px) {
  .home_hot {
    padding: 1rem;
  }

  .home_cold {
    padding: 1rem;
  }

  .home_input_unit {
    width: 3rem;
  }

  .home_input_title {
    align-self: flex-start;
    margin-top: .5rem;
  }
}

@media (max-width: 1024px) {

  .home_main {
    display: block;
  }

  .home_containerHotCold {
    width: 100%;
  }

  .home_results {
    margin: 0;
    width: 100%;
    position: absolute;
  }

  .home_results_container {
    height: inherit;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    background-color: var(--colorMainDark);
  }

  .home_results_container.opened {
    height: 100vh;
    top: 0;
  }

  .home_results_nav {
    display: flex;
  }

  .home_results_nav_mobile {
    height: 5rem;
    position: fixed;
    bottom: 0;
    display: flex;
  }

  .home_results_visible {
    display: block;
    max-height: calc(100vh - 6rem);
    height: 100%;
    overflow-y: scroll;
  }

  .home_results_invisible {
    display: none;
  }

  .home_results_closeSVG {
    visibility: visible;
  }
}
