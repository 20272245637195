.login_button {
    color: #fff;
    height: 100%;
    font-size: 20px;
    margin-right: 7px;
}

.auth_icon {
    width: 30px;
}

@media screen and (max-width: 639px) {
    .login_button {
        font-size: 16px;
        margin-right: 5px;
    }

    .auth_icon {
        width: 20px;
    }
}