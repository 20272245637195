.est_button {
  color: var(--colorWhite);
  width: 100%;
  padding: 0.6rem 2rem;
  cursor: pointer;
  border: 1px solid white;
  text-align: center;
  transition: all 150ms ease-in-out;
  transition-delay: 100ms;
}

.est_button:hover {
  background: linear-gradient(to right, transparent 50%, var(--colorWhite20) 50%) right;
  background-size: 200% 100%;
}

.est_button:active {
  box-shadow: 0 0 1rem var(--colorWhite20);
}



/*     Media queries    */

@media (max-width: 576px) {
  .est_button {
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 768px) {

}

@media (max-width: 992px) {

}

@media (max-width: 1200px) {
}
